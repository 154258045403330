import React from 'react';
import './Reservation.css';
import { MdOutlineRestaurantMenu } from 'react-icons/md';

function Reservation(props) {
  return (props.trigger) ? (
    <div className='popup'>
        <div className='popup-inner'>
            <div className='popup-inner-tab'>
            <p>Please inform the restaurant in advance if you have any dietary restrictions!</p>
                <MdOutlineRestaurantMenu className='overlay__close' onClick={() => props.setTrigger(false)}/>
            </div>
            <div className='popup-inner-content'>
                {props.children}
            </div>
            
        </div>
    </div>
  ) : "";
}

export default Reservation