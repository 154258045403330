import React from 'react';
import { SubHeading2 } from '../../components';
import './FindUs.css';

const FindUs = () => (
  <div className='app__bg app__wrapper section__padding' id='contact'>
    <div className='app__wrapper_info'>
      <SubHeading2 title='Contact' />
      <h1 className='headtext__josefinsans' style={{ marginBottom: '3rem'}}>Find Us</h1>
      <div className='app__wrapper-content'>
        <p className='p__opensans-alt'>ONMI Restaurant</p>
        <p className='p__opensans-alt'>107 Amoy St</p>
        <p className='p__opensans-alt'>Singapore 069927</p>
        <p className='p__opensans-alt'>
          <br/>For reservation: <a href="mailto:reservation@onmi-sg.com">
            reservation@onmi-sg.com
          </a>
        </p>
        <p className='p__opensans-alt'>
          <br/>General inquiries: <a href="mailto:contact@onmi-sg.com">
            contact@onmi-sg.com
          </a>
        </p>
        <p className='p__josefinsans-alt' style={{margin: '2rem 0'}}>Opening Hours</p>
        <p className='p__opensans-alt'>Lunch</p>
        <p className='p__opensans-alt'>Wednesday - Thursday</p>
        <p className='p__opensans-alt'>11:30am to 2:30pm</p>
        <p className='p__opensans-alt'>(Last seating 1:30pm)</p>
        <p className='p__opensans-alt'><br/>Dinner</p>
        <p className='p__opensans-alt'>Tuesday - Saturday</p>
        <p className='p__opensans-alt'>6:00pm to 10:30pm</p>
        <p className='p__opensans-alt'>(Last seating 8:30pm)</p>
        <p className='p__opensans-alt'><br/>Closed on Sunday & Monday</p>
      </div>
      <a href="https://goo.gl/maps/AgKdQSdKPtfKoui1A" target='_blank' rel='noreferrer' className='custom__button' style={{ marginTop: '2rem'}}>Visit Us</a>
    </div>
    <div className='app__wrapper_img pt-2'>
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8198821335136!2d103.84533261533124!3d1.2818308621458647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da192bc096b5a7%3A0x310e4723eded302!2sONMI!5e0!3m2!1sen!2ssg!4v1678694815347!5m2!1sen!2ssg" 
        allowfullscreen="" 
        loading="lazy" 
        style={{border:"0"}}
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* <img src={images.findus} alt='findus' /> */}
    </div>
  </div>
);

export default FindUs;
