import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';
import Reservation from './Reservation';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [buttonPopup, setButtonPopup] = React.useState(false)
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.onmi} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="app__nav-p"><a href="#home">Home</a></li>
        <li className="app__nav-p"><a href="#about">About</a></li>
        <li className="app__nav-p"><a href="#menu">Menu</a></li>
        <li className="app__nav-p"><a href="#chef">Chef</a></li>
        <li className="app__nav-p"><a href="#contact">Contact</a></li>
      </ul>
      {/* <div className="app__navbar-login">
        <a href="http://www.sevenrooms.com/reservations/onmi" className="app__nav-p">Reserve a Table</a>
      </div> */}

      <div className='app__navbar-login'>
        <button onClick={() => setButtonPopup(true)} className='app__nav-r'>Reserve a Table</button>
        <Reservation trigger={buttonPopup} setTrigger={setButtonPopup}>
          
          <p className='p__opensans-alt'>We would like to accommodate to your needs and requirements to our best ability. Please inform us <b>at least 24 hours</b> prior to your reservations of any dietary restrictions. Kindly note that we are unable to accommodate to certain shellfish, dairy, vegetarian and gluten-free dietary restrictions due to the nature of the cuisine. Due to limitations, we will not be able to accommodate to last minute requests.</p>
          <div className="app__navbar-reserve">
            <a href="http://www.sevenrooms.com/reservations/onmi" className="app__nav-ra">I agree</a>
          </div>
        </Reservation>
      </div>
      

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li>
              <li><a href="#chef" onClick={() => setToggleMenu(false)}>Chef</a></li>
              <li><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
              {/* <li><a href="http://www.sevenrooms.com/reservations/onmi" onClick={() => setToggleMenu(false)}>Reserve a Table</a></li> */}
              <li>
                <button onClick={() => {setButtonPopup(true)}} className='app__nav-rs'>Reserve a Table</button>
                <Reservation trigger={buttonPopup} setTrigger={setButtonPopup}>
                  
                  <p className='p__opensans-alt'>Nothing is more important to us than a pleasant dining experience of our guests. 
                    We would like to accommodate to your needs and requirements to our best ability. 
                    Please inform us <b>at least 24 hours prior</b> to your reservations of any dietary restrictions. 
                    Kindly note that we serve a variety of seafood (raw included) as well as meat. 
                    We seek your kind understanding that due to limitations, we will not be able to accommodate 
                    to last minute requests.</p>
                  <div className="app__navbar-reserve">
                    <a href="http://www.sevenrooms.com/reservations/onmi" className="app__nav-ra">I agree</a>
                  </div>
                </Reservation>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;