import React from 'react';

const SubHeading = ({ title }) => (
  <div style={{ marginBottom: '1rem' }}>
    <p className='p__josefinsans' style={{fontWeight: '300'}}>{title}</p>
    {/* <img src={images.chopstick} alt='chopstick' className='chopstick__img' /> */}
  </div>
);

export default SubHeading;
