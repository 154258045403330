import React from 'react';
import { SubHeading2 } from '../../components';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div id='about' className='app__bg app__wrapper section__padding'>
    <div className='app__wrapper_img app__wrapper_img-reverse'>
      <img src={images.aboutus} alt='about us' />
    </div>
    <div className='app__wrapper_info'>
      <SubHeading2 title="About Us"/>
      <h1 className='headtext__josefinsans'>Who we are</h1>
      <div className='app__chef-content'>
        <p className='p__opensans-alt' style={{textTransform: 'none'}}>ONMI blends the dining experience of 
        Korean traditional cuisine together with the hallmarks of a modern Korean art pieces 
        and interior design. ONMI has respected traditional Korean cuisine as the foundation for its 
        cooking but it keeps striving to explore new techniques, humors, and sensibilities. ONMI strives 
        to serve its guests through a menu of sophisticated tastes and techniques, hence creating 
        its traditional yet trendy flavors of Korea.</p>
      </div>
    </div>
  </div>
);

export default AboutUs;
