import React from 'react';
import { FiInstagram } from 'react-icons/fi';

// import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <FooterOverlay />
    <Newsletter /> */}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">107 Amoy St 069927</p>
        <p className="p__opensans">
          <a href="mailto:contact@onmi-sg.com">
            contact@onmi-sg.com
          </a>
        </p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.onmi} alt="footer_logo" />
        <br/>
        {/* <img src={images.chopstick} alt="breakpoint" className="chopstick__img" style={{ marginTop: 15 }} /> */}
        <div className="app__footer-links_icons">
          <a href='https://www.instagram.com/its_onmi/'>
            <FiInstagram/>
          </a>
          
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Tuesday - Saturday</p>
        <p className="p__opensans">6:00pm to 10:30pm</p>
        <p className="p__opensans">Closed on Sunday & Monday</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 ONMI. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;