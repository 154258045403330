import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className='app__header app__wrapper section__padding' id='home'>
    <div className='app__wrapper_info'>
      <SubHeading title='Traditional but Colorful' />
      <h1 className='app__header-h1'>Your Classy Korean Dining Experience</h1>
      <p className='p__opensans' style={{ margin: '2rem 0'}}>Hello, we are ONMI. 
      We have arrived in Singapore with a dedication to provide you with classy Korean dining experience.
      Come enjoy our traditional but colorful tasting menu at a reasonable price, accompanied by the drinks
       list that you can only find at our venue, decorated with the art pieces brought all the way over 
       from the Korean Peninsula. Our mantra is as simple as our name: your ultimate Korean dining 
       experience is on me.</p>
    </div>
    <div className='app__wrapper_img'>
      <img src={images.welcome} alt='header img' />
    </div>
  </div>
);

export default Header;
